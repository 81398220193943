.card {
  height: 300px;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: ease-out 0.3s;

  &:hover {
    transform: scale(1.1);
  }

  &-icon {
    height: 70px;
    width: 70px;
  }
}

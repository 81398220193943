.heading-bg {
  width: 100%;
  background-image: url("../assets/header5.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.heading-title {
  margin-top: 40px;
  position: absolute;
  top: 40%;
  left: 40%;
  z-index: 100;
  transform: translate(-50%, -50%);
  width: 51%;
}

.heading-button {
  background-color: #033046;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: white;
    color: #034463;
  }
}

.header {
  position: absolute;
  z-index: 100;

  left: 50%;
  right: 50%;
  transform: translate(-50%, 0);
  transition: 0.3s ease-in;

  &-link:hover {
    background-color: white;
    color: #034463;
  }

  &-visible {
    background-color: #033046;
    position: fixed;
    z-index: 200;
  }

  &-invisible {
    background-color: transparent;
  }
}

.mobile {
  position: absolute;
  z-index: 200;
  background-color: #033046;

  &-burger-wrapper {
    position: relative;
  }

  &-burger-menu {
    position: absolute;
    top: 60px;
    right: 0;
    height: 300px;
    background-color: #033046;
  }
}

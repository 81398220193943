.about {
  height: fit-content;

  &-image {
  }
}

li {
  padding: 3px 0;
  max-width: 200px;
  justify-content: space-between;
  list-style: disc outside none;
  display: list-item;
  color: #fff;
  list-style: disc;
}

.about-business-list {
  background-color: #034463;
}
